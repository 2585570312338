<template class="app">

    <div class="main--binder" :theme="theme">

        <TheNavigation class="main__boundary main__boundary--top" />

        <div class="main__box">
            <router-view v-slot="{ Component, route }"><div class="main__box--binder">
              <transition :name="route.meta.transition || 'fade'"><keep-alive>
                
                    <component :is="Component" class="main" />
                
              </keep-alive></transition>
            </div></router-view>

            <img v-if="isHomeComponent" class="main-background" src="@/assets/background/rax_flying--new_version_png.png" alt="" aria-hidden="true" />
            <div v-else class="main-background" aria-hidden="true" />
        </div>


        <TheFooter class="main__boundary main__boundary--bottom" v-model="theme" :theme="theme" :dark-mode="darkMode" />

        <div id="backdrop" aria-hidden="true">
            <img src="@/assets/background/nebula_red.jpg" alt="">
        </div>

    </div>
</template>


<script>
import TheNavigation from '@/components/TheNavigation.vue';
import TheFooter from '@/components/TheFooter.vue';

export default {
  components: {
    TheNavigation, TheFooter
  },
  data() {
    return {
      theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
      darkMode: false
    }
  },
  computed: {
    darkMode() {
      return this.theme === 'light' ? true : false
    },
    isHomeComponent() {
      return this.$route.name === 'home';
    },
  },
};
</script>

<style lang="scss">
@mixin tablet {
  @media only screen and (width > 600px) {
    @content;
  }
}
@mixin laptop {
  @media only screen and (width > 900px) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (width > 1200px) {
    @content;
  }
}
@mixin ultrawide {
  @media only screen and (width > 1940px) {
    @content;
  }
}
@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}


:root {
  --nav-height: 3rem;
  --header_font: 'Titillium Web', sans-serif;
  --main_font: 'Inter', sans-serif;
  --art_font: "Permanent Marker", cursive;
  --font_color: #fff;
  --rgb: linear-gradient(to right, #0bf, #0fb, #ffaa00, #f30);

  --dark_000: #000;
  --dark_100: #101010;
  --dark_200: #1a1a1a;
  --dark_400: #2a2a2a;
  --dark_800: #323232;
  --glass_dark: #01010199;

  --light_900: #fff;
  --light_800: #eaeaea;
  --light_600: #acacac;
  --light_400: #a1a1a1;
  --light_300: #858585;
  --glass_light: #fefefe99;

  --green_100: #055336;
  --green_200: #0b885a;
  --green_300: #19be80;
  --green_500: #22ffbb;

  --orange_600: #e38251;
  --orange_500: #ff6d23;

  --blue_300: #004158;
  --blue_400: #006488;
  --blue_500: #0082b2;
  --blue_600: #00a5e1;
  --blue_700: #60d5ff;
  --blue_800: #a4e7ff;

  --nav_base: var(--dark_100);
  --nav_text: var(--light_900);
}




*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
    scroll-behavior: smooth;

    /* FIREFOX scrollbar */
    scrollbar-width: thin;
    scrollbar-color: var(--_text-base, --green_500) var(--_border-base, --dark_200); /*thumb | track*/
}
/* other scrollbars */
*::-webkit-scrollbar{
    height: .5rem; width: .5rem;
}
*::-webkit-scrollbar-track {
    width: 0; height: 0;
    background-color: var(--dark_200);
    border-radius: .5rem;
}
*::-webkit-scrollbar-thumb {
    background: var(--green_500);
    border-radius: .5rem;
}

/* text highlight color when selected with cursor */
::selection {
  background: var(--green_500);
  color: var(--dark_100);
  text-shadow:  0 0 1px var(--green_200),
                0 0 3px var(--light_800)
                ;
  filter: none;
}

:target {
  scroll-margin-top: 3rem; /* Where anchor scrolls to */
}

// TRANSITION FOR PAGE CHANGES
// FADE
.fade-enter-active, .fade-leave-active { position: absolute; inset: 0; }
.fade-enter-from, .fade-leave-to { opacity: 0; }
.fade-enter-to, .fade-leave-from { opacity: 1; }
// SLIDE
.slide-left-enter-active, 
.slide-right-enter-active, 
.slide-left-leave-active, 
.slide-right-leave-active {
  position: absolute; inset: 0;
  transition: transform 350ms ease-in-out;
}

.slide-left-enter { transform: translateX(100%); }
.slide-right-enter { transform: translateX(-100%); }

.slide-left-leave { transform: translateX(100%); }
.slide-right-leave { transform: translateX(-100%); }

.slide-left-leave-to { transform: translateX(-100%); }
.slide-right-leave-to { transform: translateX(100%); }

.slide-left-enter-from { transform: translateX(100%); }
.slide-right-enter-from { transform: translateX(-100%); }
.slide-left-enter-to { transform: translateX(0); }
.slide-right-enter-to { transform: translateX(0); }



#app {
  --_desk-height: 75vh;

  font-family: var(--main_font, Arial, sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  display: grid;
  place-items: center;
  height: 100dvh;
  padding: 0;

  @include tablet { padding: 1rem; }
  @include desktop { padding: 0 10vw; }
}
body { background-color: var(--dark_100); }

h1, h2, h3, h4, h5, h6 { font-family: var(--header_font); font-weight: 700; }
h1, h2 { font-size: clamp(1.3rem, 4vw, 1.6rem); letter-spacing: 1px; }
h3, h4 { font-size: clamp(1.2rem, 3.5vw, 1.4rem); }
h5, h6 { font-size: clamp(1rem, 3vw, 1.2rem); font-weight: 600; }
a, p, li, button { font-family: var(--main_font); font-size: clamp(0.75rem, 2vw, 1rem); }
p { line-height: 145%; margin-block-end: 1rem; }
a {
  color: var(--_alink_base); text-decoration-style: dotted; text-underline-offset: .25rem;
  transition: color 350ms ease;
  &:visited { color: var(--_alink_visited); }
  &:hover { color: var(--_alink_hover); text-decoration-style: solid; text-decoration-thickness: 2px; }
  &:active { color: var(--_alink_active); }
}

button {
  --_btn-corner: .8rem; --_btn-corner-morph: var(--_btn-corner);
  --_btn-gradient: linear-gradient(currentColor 0 0);
  --_btn-underline: 0.2em;

  padding: .5rem 1rem;
  background: var(--_btn-body);
  background: var(--_btn-gradient) bottom center / var(--underline-width, 0%) var(--_btn-underline) no-repeat;
  &.special { 
    --_btn-gradient: var(--rgb);
    &:active { --_btn-gradient: var(--rgb); }
  }

  color: var(--_text-base);
  border: 2px solid var(--_border-base);
  transition: background 350ms ease, clip-path 300ms ease-out 100ms;

  &:hover, &:focus-visible {
    --underline-width: 100%;
    background-color: var(--_border-base);
  }
  &:active { 
    --_btn-underline: 100%;
    // --_btn-gradient: linear-gradient(var(--_body-shade) 0 0);
    background: var(--_btn-body);
    background: var(--_btn-gradient) bottom center / var(--underline-width, 0%) var(--_btn-underline) no-repeat;

  }

  // SIMPLE LINK CONTAINER BUTTON structure: [button.btn__router>a.btn__router--link]
  &.btn__router {
    padding: .5rem 0;
    a.btn__router--link {
      padding: .5rem 1rem;
      text-decoration: none;
      color: var(--_btnlink-color);
      &:hover { color: var(--_btnlink-color_hover); }
      &:active { color: var(--_border-base); }
    }
  }

  // FANCY DISPLAY BUTTON structure: [ button.btn--base>div.btn ]
  &.btn--base {
    border: none;
    padding: 5px;
    background: var(--_text-base);
    &.highlight { background: var(--rgb); }

    clip-path: polygon(
               var(--_btn-corner) 0, 
               100% 0, 
               100% calc(100% - var(--_btn-corner-morph)), 
               calc(100% - var(--_btn-corner)) 100% ,
               0 100%,
               0 var(--_btn-corner-morph)
               );
    &:hover, &:focus-visible,
    &:hover .btn, &:focus-visible .btn { --_btn-corner-morph: .01rem; }
    &:hover, &:focus-visible { transition: background 350ms ease, clip-path 380ms ease-in; }
    &:active { 
      --_btn-corner: .25rem;
      --_btn-corner-morph: .25rem; 
      .btn { 
        background: var(--_body-hover);
        --_btn-corner: .25rem;
        --_btn-corner-morph: .25rem; 
      } 
    }
  }

  .btn {
    --_btn-corner: .7rem;
    background: var(--_body-base);
    padding: .5rem 1rem;
    overflow: hidden;
    clip-path: polygon(
               var(--_btn-corner) 0, 
               100% 0, 
               100% calc(100% - var(--_btn-corner-morph)), 
               calc(100% - var(--_btn-corner)) 100% ,
               0 100%,
               0 var(--_btn-corner-morph)
               );
    transition: background 350ms ease, clip-path 380ms ease-in;
  }
}

.main {
  &__box {
    &--binder { 
      position: relative; 
      height: var(--_desk-height); width: 100%;
    }
    
    position: relative;
    top: 0; left: 0;
    @include tablet { height: calc(100vh - 6rem - 2rem); }
    @include desktop { height: var(--_desk-height); }

    .main-background {
      position: absolute; inset: 0;
      height: calc(100dvh - 6rem); width: 100%;
      object-fit: cover;
      overflow-x: clip;

      background: linear-gradient(to bottom, var(--_body-shade), #233);

      @include tablet { height: calc(100vh - 6rem - 2rem); }
      @include desktop { height: var(--_desk-height); }
    }
  }

  position: absolute; inset: 0;
  // position: relative;
  z-index: 8;
  height: calc(100dvh - 6rem);
  overflow: auto; overflow-x: clip;
  
  padding: 2rem;
  color: var(--_text-base);
  background: var(--_glass);
  border-block: 1px solid var(--_body-base);
  
  // opacity transition for router transition fade!!
  transition: background 400ms ease, color 350ms ease, opacity 350ms ease;

  @include tablet { height: calc(100dvh - 6rem - 2rem); }
  @include desktop { height: var(--_desk-height); }
  
  &__boundary {
    // nav & footer
    --_corner: 2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;

    height: calc(var(--_corner) + 1rem); width: 100%;
    padding-inline: 1rem;
    overflow: hidden;

    background: var(--_border-base);
    color: var(--_text-base);

    transition: background 300ms ease-in-out, color 250ms ease;

    &--bottom { position: fixed; bottom: 0; }

    @include tablet {
      padding-inline: 2rem;
        &--top {
          padding-inline-start: calc(var(--_corner) + 1rem);
          // Clip has to include the height of the navigation menu when openend or else it'll be clipped out!
          clip-path: polygon(var(--_corner) 0, 100% 0, 100% calc(100dvh - 4rem), 0 calc(100dvh - 4rem), 0 var(--_corner));
        }
        &--bottom {
          padding-inline-end: calc(var(--_corner) + 1rem);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--_corner)), calc(100% - var(--_corner)) 100%, 0 100%);
          @include tablet { position: relative; }
        }
    }
  }



  &--binder {

    position: fixed; top: 0;

    width: 100dvw;
    max-height: 100dvh;
    max-width: 1200px;
    // height: 100dvh;
    @include tablet { top: 1rem; width: calc(100dvw - 2rem); }
    @include desktop { position: relative; width: 100%; }


    // Theme Variables

    &[theme="dark"] {
    --_body-base: var(--dark_200);
    --_body-shade: var(--dark_000);
    --_body-hover: var(--dark_400);
    --_border-base: var(--dark_100);
    --_text-base: var(--light_900);
    --_glass: var(--glass_dark);
    --_accent: var(--green_500);
    --_logo-filter: brightness(1);

    --_btn-body: var(--_body-hover);
    --_btnlink-color: var(--blue_800);
    --_btnlink-color_hover: var(--blue_700);
    
    --_navlink_base: var(--blue_800);
    --_navlink_hover: var(--blue_700);

    --_alink_base: var(--blue_700);
    --_alink_visited: var(--blue_600);
    --_alink_hover: var(--blue_700);
    --_alink_active: var(--blue_600);
    }

    &[theme="light"] {
    --_body-base: var(--light_800);
    --_body-shade: var(--light_600);
    --_body-hover: var(--light_400);
    --_border-base: var(--light_900);
    --_text-base: var(--dark_200);
    --_glass: var(--glass_light);
    --_accent: var(--green_100);
    --_logo-filter: brightness(0);

    --_btn-body: var(--_body-base);
    --_btnlink-color: var(--blue_300);
    --_btnlink-color_hover: var(--blue_400);

    --_navlink_base: var(--blue_300);
    --_navlink_hover: var(--blue_400);

    --_alink_base: var(--blue_500);
    --_alink_visited: var(--blue_300);
    --_alink_hover: var(--blue_500);
    --_alink_active: var(--blue_300);
      }
    }
}

#backdrop {
  user-select: none;
  position: fixed; inset: 0; 
  z-index: -1;
  height: 100dvh; width: 100dvw;
  overflow: hidden;
  background: #1a1a1a99;

  img {
      height: 100dvh; width: 100dvw;
      object-fit: cover;
      filter: blur(21px);
      mix-blend-mode: hard-light;
  }
  
}

</style>
