<template>
  <div class="home">
    <div class="home__logo">
        <h1 class="home__logo--txt">FusionRift <br><span id="h-a" data-text="ART">ART</span> <span id="h-b">Studio</span></h1>
    </div>

    <div class="home__stub">
      <p class="home__stub--txt" v-show="showStub">
        FusionRift, an experienced artist with over two decades of dedication to both digital 
        and traditional art, blends various techniques. Their portfolio prominently features 
        anthropomorphic characters, often portraying canines and dragons. Beyond the canvas, 
        FusionRift excels in UI/UX design, web development, and branding. 
        <router-link :to="{name: 'about'}" class="home__stub--lnk">Read more</router-link>
        <span class="home__stub--hide" title="close" @click="showStub = false">x</span>
      </p>
    </div>
    
    <div class="home__buttons--container">

        <button v-for="(button, index) in mainButtons" 
            class="btn--base"
            :class="{'highlight': button.special}"
            :key="index"
            @click.prevent="$router.push({name: button.route})"
            >
            <div class="btn">{{ button.text }}</div>
        </button>
        
    </div>

    <div class="home__socials home__socials--container">
        <a v-for="payment in paymentLinks" 
          class="home__socials--link"
          :class="payment.platform.toLowerCase()"
          :href="payment.link" target="_blank" 
          :alt="payment.alt"
          :title="payment.handle + ' on ' + payment.platform">
            <img 
              class="home__socials--icon"
              :class="payment.platform.toLowerCase()"
              :src="require('@/assets/icon/' + payment.icon)"
              :alt="payment.platform + ' Icon'"
              aria-hidden="true"
              >
        </a>
        <a v-for="contact in socialLinks" 
          class="home__socials--link"
          :class="contact.platform.toLowerCase()"
          :href="contact.link" target="_blank" 
          :alt="contact.alt"
          :title="contact.handle + ' on ' + contact.platform">
            <img 
              class="home__socials--icon"
              :class="contact.platform.toLowerCase()"
              :src="require('@/assets/icon/' + contact.icon)"
              :alt="contact.platform + ' Icon'"
              aria-hidden="true"
              >
        </a>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
    
  },
  data() {
    return {
      showStub: true,
      mainButtons: [
        {
          text: "About",
          route: "about",
          special: false
        },
        {
          text: "Gallery",
          route: "gallery",
          special: false
        },
        {
          text: "Commission",
          route: "art",
          special: true
        },
        {
          text: "T.o.S.",
          route: "art.tos",
          special: false
        },
      ],
      socialLinks: [
        {
          platform: 'Discord',
          handle: '@fusionrift',
          alt: '',
          icon: 'discord-mark-white.svg',
          link: 'https://discordapp.com/users/384523818821091339',
        },
        {
          platform: 'Telegram',
          handle: '@fusionrift',
          alt: '',
          icon: 'telegram_logo-nocricle.svg',
          link: 'https://t.me/fusionrift',
        },
        {
          platform: 'Bluesky',
          handle: '@fusionrift.bsky.social',
          alt: '',
          icon: 'Bluesky_Logo.svg',
          link: 'https://bsky.app/profile/fusionrift.bsky.social'
        },
        {
          platform: 'Cara',
          handle: '@fusionrift',
          alt: '',
          icon: 'cara_svg_white.svg',
          link: 'https://cara.app/fusionrift',
        },
        {
          platform: 'FurAffinity',
          handle: '~FusionRift',
          alt: '',
          icon: 'furaffinity_white.svg',
          link: 'https://www.furaffinity.net/user/fusionrift',
        },
      ],
      paymentLinks: [
        {
          platform: 'Ko-Fi',
          handle: 'FusionRift',
          alt: '',
          icon: 'kofi_bg_tag_dark.png',
          link: 'https://ko-fi.com/fusionrift',
        },
        {
          platform: 'PayPal',
          handle: '@fusionriftstudio',
          alt: '',
          icon: 'PayPal-transparent_white.svg',
          link: 'https://paypal.me/fusionriftstudio'
        },
      ]
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
@mixin tablet {
  @media only screen and (width > 600px) {
    @content;
  }
}
@mixin laptop {
  @media only screen and (width > 900px) {
    @content;
  }
}

@keyframes float-in {
  from { opacity: 0; transform: translateY(5rem); }
  to { opacity: 1; transform: translateY(0); }
}

.home {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;

    background: transparent;

    &__logo {
      --_banner-txt-size: 3rem;

      display: grid;
      place-items: center;
      grid-template: "logo";

      padding: 2rem;

      @include tablet { justify-content: end; }

      &--txt {
        grid-area: logo;
        text-align: center;
        position: relative;
        font-size: var(--_banner-txt-size);
        font-weight: 800;
        line-height: 90%;

        background: var(--rgb);
        background-clip: text;
        -webkit-text-stroke: .1em transparent;

        #h-a {
          position: absolute; bottom: 0;
          z-index: 10;
          font-family: var(--art_font);
          // font-style: italic;
          font-size: calc(var(--_banner-txt-size) * 1.4);
          padding-inline-end: 1.2rem;
          padding-block: .5rem;

          color: transparent;
          background: var(--rgb);
          background-clip: text;
          -webkit-text-stroke: 1px var(--_text-base);
          transform: translate(calc(var(--_banner-txt-size) * -0.75), calc(var(--_banner-txt-size) / 2.75)) rotate(18deg);
          
        }
        #h-b { padding-inline-start: calc(var(--_banner-txt-size) * 1.2); }
      }
      &--img {
        grid-area: logo;
        width: 50vw;
        opacity: 0;
        visibility: hidden;
      }
    }

    &__stub {
      position: relative;
      background: var(--_glass);
      border-radius: .5rem;
      margin: 1rem;
      margin-block-end: auto;
      animation: float-in forwards 850ms ease-in-out;
      backdrop-filter: blur(7px);
      @include tablet { align-self: end; max-width: 275px; &--txt, &--lnk {font-size: clamp(0.70rem, 1.2vw, 0.8rem);} }
      @include laptop { align-self: end; max-width: 450px; }
      &--txt { margin-block-end: .5rem; padding: 1rem; }
      &--hide {
        cursor: pointer;
        position: absolute;
        right: 0; top: 0;
        padding: .5rem .75rem;
        padding-inline-start: 2rem;
        padding-block-end: 2rem;
      }
    }

    &__buttons {
      &--container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1rem;
        padding: 2rem 1.5rem 1rem;
        background: var(--_glass);
        transition: background 400ms ease, color 350ms ease;
        @include laptop {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
        }
      }
    }

    &__socials {

      --_soc_mediaicon: 1.5rem;
      --_soc_payicon: calc(var(--_soc_mediaicon) / 1.35);

      @include tablet {
      --_soc_mediaicon: 2.5rem;
      --_soc_payicon: calc(var(--_soc_mediaicon) / 1.35);
        &--container { gap: 1rem; }
      }

      &--container {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-end;
        gap: .5rem;
        padding: 0 1.5rem 1rem;
        background: var(--_glass);
        transition: background 400ms ease, color 350ms ease;
      }
      &--link {
        order: 2;
        &.ko-fi, &.paypal { order: 1; }
        &.discord { margin-inline-start: auto; }

        transition: transform 300ms cubic-bezier(0.26, 2.19, 0.68, 0.72);;
        &:hover { transform: scale(1.1); }
        &:active { transform: scale(0.9); }
      }
      &--icon {
        width: var(--_soc_mediaicon);
        filter: var(--_logo-filter);
        &.ko-fi, &.paypal { width: auto; }
        &.paypal { height: var(--_soc_payicon); }
        &.ko-fi { height: calc(var(--_soc_payicon) * 1.1); filter: none; }
      }
    }
    
  }
</style>