<template>
    <div class="footer">
            
        <div class="footer__item copyright">
          © 2023 - {{ getYear }} FusionRift <!-- | <router-link>Impressum</router-link> -->
        </div>

        
        <div class="footer__item">
          <label class="smol-label" for="Mode">Theme:<br>{{ theme[0].toLocaleUpperCase() + theme.slice(1) }}</label>
           
          <button :aria-pressed="themeMode"
                @click.prevent="themeMode = !themeMode; updateTheme(siteTheme)"
                class="themetoggle" 
                :class="{'light': themeMode}"
                :title="themeMode ? 'Turn on Dark Mode' : 'Turn on Light Mode'"
                :aria-label="themeMode ? 'Dark Mode' : 'Light Mode'"
                id="Mode"
          />

        </div>

    </div>
</template>

<script>
export default {
    props: {
        theme: String,
        darkMode: Boolean
    },
    data() {
        return {
            themeMode: this.darkMode
        }
    },
    computed: {
        siteTheme() {
            return this.themeMode ? 'light' : 'dark'
        },
        getYear() {
          let year = new Date
          return year.getUTCFullYear()
        }
    },
    methods: {
    updateTheme(newTheme) {
      this.$emit('update:modelValue', newTheme); // Update the model value
    }
  }
};
</script>

<style lang="scss">
.footer {

      &__item {
        display: flex;
        align-items: center;
        gap: .5rem;

        &.copyright {
          font-family: var(--main_font);
          font-size: clamp(0.75rem, 2vw, 1rem);
          > a {color: var(--_text-base);}
        }

        .smol-label {
          text-align: end;
          font-size: 0.65rem;
          line-height: 0.75rem;
          text-transform: uppercase;
        }
      }

    .themetoggle {
      
      input { display: none; visibility: collapse; }
        position: relative;
        height: 1.4rem;
        width: 2.35rem;
        background: var(--_text-base);
        border: 1px solid #333;
        border-radius: 1rem;
      &::before {
        content: ' ';
        // content:'☀';
        font-size: .85rem;
        color: var(--_body-base);
        line-height: 130%;
        position: absolute;
        top: .15rem; left: .15rem;
        height: 1rem; width: 1rem;
        background: var(--_body-base);
        border-radius: 1rem;
        transition: transform 500ms ease, border-radius 230ms ease-out, color 300ms ease;
      }
      &::after {
        content: '';
        height: 1rem; width: 1rem;
        top: .15rem; left: .65rem;
        border-radius: 1rem;
        position: absolute;
        background: var(--nav_text);
        transition: transform 230ms ease-out, border-radius 230ms ease-out, color 230ms ease;
      }
      &.light {
        &::before { color: var(--_text-base); transform: translateX(.9rem); }
        &::after { transform: scale(0); }
      }
    }
}
</style>