<template>
    <div class="nav">

        <TheLogo />

        <button id="mobile-menu" 
                role="navigation" 
                title="Access Navigation Menu" 
                @click.prevent="mobileMenuStatus()"
                :class="{'open': mobile, 'closed': !mobile}"
                :aria-expanded="mobile"
            >
            <img src="@/assets/icon/mobile.svg" alt="menu icon" aria-hidden="true" class="burger">
            <img src="@/assets/icon/close.svg" alt="menu icon" aria-hidden="true" class="closer">
        </button>

        <nav class="nav__menu" :class="{'show': mobile, 'hide': !mobile}" :aria-expanded="mobile" title="Navigation Menu">
            <router-link v-for="link in navLinks"
                class="nav__link"
                :class="{ 'nav__link--sub': link.sublink }"
                :to="{ name: link.route }"
                @click.prevent="mobileMenuStatus()"
                >{{ link.displayName }}</router-link>
        </nav>

    </div>
</template>

<script>
import TheLogo from '@/components/TheLogo.vue';

export default {
    components: {
        TheLogo
    },
    data() {
        return {
            mobile: false,
            navLinks: [
                {
                    displayName: 'Home',
                    route: 'home',
                    sublink: false
                },
                {
                    displayName: 'Gallery',
                    route: 'gallery',
                    sublink: false
                },
                {
                    displayName: 'Commission',
                    route: 'art',
                    sublink: false
                },
                {
                    displayName: 'General Information',
                    route: 'art.info',
                    sublink: true
                },
                {
                    displayName: 'Terms of Service',
                    route: 'art.tos',
                    sublink: true
                },
                // {
                //     displayName: 'Order Custom Art',
                //     route: 'art.order',
                //     sublink: true
                // },
                {
                    displayName: 'Contact',
                    route: 'contact',
                    sublink: false
                },
            ]
        }
    },
    methods: {
        mobileMenuStatus() {
            if (window.innerWidth > 940) { return this.mobile = true  }
            else { return this.mobile = !this.mobile }
        }
    },
};

</script>

<style lang="scss">
@mixin tablet {
  @media only screen and (width > 600px) {
    @content;
  }
}
@mixin laptop {
  @media only screen and (width > 940px) {
    @content;
  }
}

#mobile-menu {
    position: fixed; right: 1rem;
    height: 1.5rem; width: 1.5rem;
    background: transparent;
    border: none;
    transform: translateX(0);
    filter: var(--_logo-filter);

    img { 
        position: absolute; inset: 0;
        height: 1.5rem; width: 1.5rem;
        transition: transform 350ms ease, opacity 350ms ease;
    }
    &.open {
        .burger { transform: rotate(-90deg); opacity: 0; }
        .closer { transform: rotate(0); opacity: 1; }
    }
    &.closed {
        .burger { transform: rotate(0); opacity: 1; }
        .closer { transform: rotate(90deg); opacity: 0; }
    }
    @include tablet { right: 2rem; }
    @include laptop { display: none; visibility: hidden; }
}
.nav {

    position: relative;
    font-family: var(--header_font);
    letter-spacing: 2px;
    font-weight: 400;
    // text-transform: uppercase;

    &__link {
        text-transform: uppercase;
        font-size: clamp(1.2rem, 3vw, 1.6rem);
        &--sub { 
            font-size: clamp(1rem, 2.5vw, 1.2rem); 
            text-transform: capitalize;
            font-weight: 300;
            margin-inline-start: 2rem;
            @include laptop { display: none; visibility: collapse; }
        }
    }

    &__menu {
        
        z-index: 100;
        position: fixed; 
        top: 3rem; left: 0;
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        width: 100dvw;
        height: calc(100dvh - 6rem);
        padding: 2.5rem;
        background: var(--_body-base);
        background-image: linear-gradient(to bottom, 
                        var(--_body-shade), 
                        var(--_body-base) 1rem,
                        var(--_body-base) 50%,
                        var(--_body-shade)
                        );

        transform: translateX(105dvw);
        transition: transform 500ms ease-in-out, visibility 0ms ease 550ms;
        &.show { 
            transform: translateX(0); visibility: visible; 
            transition: transform 500ms ease-in-out;
        }
        &.hide { visibility: collapse; }

        @include tablet { top: 4rem; height: calc(100dvh - 6rem - 2rem); }
    }

    @include laptop {
        
        &__menu {
            flex-direction: row;
            transform: none;
            gap: 1rem;
            padding: 0;
            width: unset;
            height: unset;
            position: unset;
            background: transparent;
            &.hide { visibility: visible; }

            a {
                font-size: revert-layer;
                position: relative;
                text-decoration: none;
                color: var(--green_200);
                padding-block: .4rem;
                background: var(--rgb) bottom center / var(--underline-width, 0%) 0.25em no-repeat;
                transition: color 250ms ease, background 350ms ease-in-out;

                &:hover, &:focus-visible { 
                    --underline-width: 75%;
                    color: var(--_navlink_hover); 
                }
                &.router-link-exact-active, &.router-link-active { 
                    --underline-width: 100%;
                    color: var(--_navlink_base); 
                }
            }
        }
    }
}
</style>