import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Art Studio'
    }
  },
  {
    path: '/about-the-artist',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: 'About the Artist'
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/GalleryView.vue'),
    meta: {
      title: 'Art Gallery'
    },
    children: [
      {
        path: '/something',
        name: 'gallery.tos',
        component: () => import(/* webpackChunkName: "gallery.tos" */ '../views/AboutView.vue'),
        meta: {
          title: 'Art Gallery: Something'
        }
      },
    ],
  },
  {
    path: '/art-commissions',
    name: 'art',
    component: () => import(/* webpackChunkName: "art" */ '../views/CommissionView.vue'),
    meta: {
      title: 'Art Commissions'
    },
    redirect: {
      name: 'art.com'
    },
    children: [
      {
        path: '',
        name: 'art.com',
        component: () => import(/* webpackChunkName: "art.com" */ '../views/CommissionServicesView.vue'),
        meta: {
          title: 'Art Commissions'
        }
      },
      {
        path: 'terms-of-service',
        name: 'art.tos',
        component: () => import(/* webpackChunkName: "art.tos" */ '../views/CommissionTermsView.vue'),
        meta: {
          title: 'Art Commissions: Terms of Service'
        }
      },
      {
        path: 'info',
        name: 'art.info',
        component: () => import(/* webpackChunkName: "art.info" */ '../views/CommissionInfoView.vue'),
        meta: {
          title: 'Art Commissions: General Information'
        }
      },
      {
        path: 'order',
        name: 'art.order',
        component: () => import(/* webpackChunkName: "art.order" */ '../views/CommissionOrderView.vue'),
        meta: {
          title: 'Art Commissions: Order Custom Art'
        }
      }
    ],
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
    meta: {
      title: 'Contact'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // tabname
  document.title = 'FusionRift • ' + to.meta.title || 'FusionRift • Art Studio';
  next();
});

export default router
